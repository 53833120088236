import React, { FC } from 'react';
import { BundleHero as Organism } from '@components/Organisms';

export const BundleHero = ({
  display,
  page_title,
  page_sub_title,
  body,
  sub_body,
  image,
  bundleVariant2024Sept,
  phoneNumber,
  phoneText,
}) => {
  return (
    <Organism
      display={display}
      pageTitle={page_title}
      pageSubTitle={page_sub_title}
      body={body}
      subBody={sub_body}
      image={image}
      bundleVariant2024Sept={bundleVariant2024Sept}
      phoneNumber={phoneNumber}
      phoneText={phoneText}
    />
  );
};
