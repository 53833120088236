import React from 'react';

import { PageHero as Molecule } from '@components/Molecules';

export const PageHero = ({
  display,
  title,
  subtitle,
  bodyContent,
  cta,
  trustpilot,
  displayPostCodeChecker,
  postcodeLabel,
  subtitleColour,
  titleColour,
  desktopImage,
  mobileImage,
}) => {
  return (
    <>
      <Molecule
        display={display}
        title={title}
        subtitle={subtitle}
        bodyContent={bodyContent}
        cta={cta}
        trustpilot={trustpilot}
        displayPostCodeChecker={displayPostCodeChecker}
        postcodeLabel={postcodeLabel}
        subtitleColour={subtitleColour}
        titleColour={titleColour}
        desktopImage={desktopImage}
        mobileImage={mobileImage}
      />
    </>
  );
};
