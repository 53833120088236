import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import { fromMarkdown } from '@lib/utils';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CarouselNextArrow, CarouselPrevArrow } from '@components/Atoms/CarouselArrow';
import Image from 'next/image';

const STYLED = {
  wrapper: styled.article`
    text-align: center;
    margin 2rem 0;
    background-color: var(--color-lightest-blue);
    padding: 2rem 1rem;
    @media ${breakpoints.desktop} {
      background-color: transparent;
    }

    @media ${breakpoints.tablet} {
      padding: 4rem 1rem 2rem;

    }
    @media ${breakpoints.desktopLarge} {
      padding: 0 3rem;
    }
  `,
  card: styled.div`
    @media ${breakpoints.desktop} {
      padding: 4rem;
      max-width: 75%;
      min-width: 38rem;
      border-radius: 1rem;
      box-shadow: 0 0 2rem rgba(0, 0, 255, 0.1), 0 0 2rem rgba(0, 0, 0, 0.15);
      background-color: var(--color-lightest-blue);
    }
    @media ${breakpoints.desktop} {
      max-width: 80%;
      min-width: 40rem;
    }
    margin: 0 auto;
  `,
  heading: styled.h2`
    font-size: 2rem;
    color: var(--color-primary);
    line-height: 1;
    font-weight: 500;
    @media ${breakpoints.desktop} {
      font-size: 2.5rem;
    }
    @media ${breakpoints.desktopXL} {
      font-size: 3rem;
    }
  `,
  subHeading: styled.h3`
    font-size: 1.25rem;
    color: black;
    margin: 0.5rem 0 2rem;
    @media ${breakpoints.desktop} {
      font-size: 1.5rem;
    }
  `,
  body: styled.div`
    * {
      font-size: 1.125rem;
      line-height: 1.3;
      color: black;
      @media ${breakpoints.tablet} {
        padding: 0 6vw;
      }
      @media ${breakpoints.desktop} {
        padding: 0 4vw;
        font-size: 1.2rem;
      }
    }
  `,
  cards: styled.div`
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0 2rem;
    @media ${breakpoints.mobilelg} {
      padding: 0 20vw;
    }
    @media ${breakpoints.desktop} {
      padding: 1rem 0 0;
      .slider {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 2rem;

        & > * {
          flex: 1;
        }
      }
    }
    @media ${breakpoints.desktopLarge} {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  `,
  cardsCard: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h5 {
      margin: 1.5rem 0 0.5rem;
      font-weight: 600;
    }

    .image {
      position: relative;
      width: 4rem;
      height: 4rem;
      margin: 0 auto;
    }
  `,
};

interface PropsI {
  display: boolean;
  heading: string;
  subHeading: string;
  body: string;
  cards: any[];
}

export const BenefitsCard = (props) => {
  const { display, heading, subHeading, body, cards }: PropsI = props;

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
      },
      {
        breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
        settings: 'unslick',
      },
    ],
  };

  if (!display) return null;
  return (
    <div className="lg:container">
      <STYLED.wrapper>
        <STYLED.card>
          <header>
            <STYLED.heading>{heading}</STYLED.heading>
            <STYLED.subHeading>{subHeading}</STYLED.subHeading>
            <STYLED.body>
              <div>
                {fromMarkdown({
                  text: body,
                  isHeading: false,
                })}
              </div>
            </STYLED.body>
          </header>
          <STYLED.cards>
            <Slider {...settings}>
              {cards?.map(({ label, text, icon }, index) => (
                <STYLED.cardsCard key={index}>
                  <div className="image">
                    <Image src={icon?.url} alt={icon?.alternativeText} layout="fill" />
                  </div>
                  <h5>{label}</h5>
                  <div>{text}</div>
                </STYLED.cardsCard>
              ))}
            </Slider>
          </STYLED.cards>
        </STYLED.card>
      </STYLED.wrapper>
    </div>
  );
};
