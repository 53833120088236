import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export default styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;

  @media ${breakpoints.desktop} {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`;
