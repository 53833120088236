import React from 'react';
import styled from 'styled-components';
import { fromMarkdown } from '@lib/utils';
import Image from 'next/image';
import { Cta } from '@components/Molecules';
import { CtaT } from '@components/Molecules/Cta/types';
import replacePlaceholderWithTags from '@lib/utils/replacePlaceholderWithTags';
import { PostcodeSearch } from '@components/Molecules/PostcodeSearch';
import { breakpoints } from '@theme/breakpoints';
import Ellipse from '@public/ellipse.png';

const Background = styled.div`
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: calc(12rem + 10vw);
    left: -5rem;
    width: calc(100% + 10rem);
    background: linear-gradient(to right, #0b03f6, #f90a22);
    z-index: -1;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;

    @media ${breakpoints.mobilelg} {
      height: calc(12rem + 10vw);
    }
    @media ${breakpoints.desktopLarge} {
      top: 0%;
      left: -140%;
      height: 100%;
      width: 168%;
      background: url(${Ellipse.src}) no-repeat right center;
      background-size: 40%;
      border-radius: 0;
      transform: scaleX(1.4);
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 0.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media ${breakpoints.desktopLarge} {
    padding-top: 6rem;
    padding-bottom: 6rem;
    padding-left: 8rem;
  }
`;

const H1 = styled.h1`
  grid-column: 1 / 2;
  grid-row: 1 / 2;

  color: white;
  font-family: var(--font-geomanist);
  font-size: calc(1.1rem + 2vw);

  font-weight: bold;

  @media ${breakpoints.mobilelg} {
    font-size: calc(1.5rem + 2.5vw);
  }

  @media ${breakpoints.desktopLarge} {
    grid-row: span 1;
    font-size: calc(4rem + 1vw);
    font-weight: 300;
    margin: 3rem 0 1rem;
    max-width: 36rem;
  }
`;

const PreHeading = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  margin-top: -0.25rem;

  @media ${breakpoints.desktopLarge} {
    grid-row: span 1;
    grid-column: 1 / 2;
    margin-top: 0;
  }

  & > * {
    color: white;
    text-align: right;
    font-family: var(--font-geomanist);
    font-size: calc(1rem + 2vw);
    font-weight: 300;

    @media ${breakpoints.mobilelg} {
      font-size: calc(1.1rem + 2.5vw);
    }

    @media ${breakpoints.desktopLarge} {
      text-align: left;
      font-size: calc(2rem + 1vw);
      line-height: 1;
    }
  }
`;

const PostHeading = styled.div`
  grid-column: span 2;
  grid-row: 3 / 4;
  background: -webkit-linear-gradient(180deg, #f4111b 30%, #0402fd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5rem;
  text-align: center;
  @media ${breakpoints.mobilelg} {
    font-size: calc(1.25rem + 5vw);
  }

  @media ${breakpoints.desktopLarge} {
    grid-row: span 1;
    grid-column: 1 / 2;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    background: transparent;
    color: white;
    text-align: left;
    font-size: calc(1.5rem + 1vw);
    margin-top: 0rem;
  }

  strong {
    font-weight: 700;

    @media ${breakpoints.desktopLarge} {
      font-weight: 400;
    }
  }
`;

const StyledImage = styled.div`
  grid-column: span 2;
  grid-row: 2 / 3;
  position: relative;
  top: -1rem;
  left: 1rem;
  height: calc(20rem + 5vw);

  @media ${breakpoints.desktopLarge} {
    top: calc(-1rem - 5vw);
    grid-column: 2 / 3;
    grid-row: 1 / 6;
    top: -2rem;
    left: -2rem;
    height: auto;
  }
  & > div {
    @media ${breakpoints.tablet} {
      max-width: 80%;
      margin: 0 auto;
    }
    & > span {
      display: block !important;
    }
  }

  img {
    width: 100% !important;
    object-fit: contain;
    object-position: center;
  }
`;

const LegalText = styled.p`
  grid-column: span 2;
  grid-row: 4 / 5;
  text-align: center;
  font-weight: 500;
  @media ${breakpoints.desktopLarge} {
    color: white;
    grid-row: span 1;
    grid-column: 1 / 2;
    text-align: left;
  }
`;
const StyledPostcodeSearch = styled.div`
  grid-column: span 2;
  grid-row: 5 / 6;
  margin-top: 1rem;
  display: flex;
  justify-content: center;

  @media ${breakpoints.desktopLarge} {
    grid-column: 1 / 2;
    grid-row: span 1;
    margin-top: 2rem;
  }

  form {
    width: 100%;
    max-width: 360px;

    @media ${breakpoints.desktopLarge} {
      max-width: 100%;
    }
  }
`;

interface PropsI {
  mainHeading: string;
  preHeadingText: string;
  postHeadingText: string;
  image: {
    url: string;
    alternativeText: string;
  };
  display: boolean;
  legalText?: string;
}

export const HeroCurvedColumns = (props) => {
  const {
    mainHeading,
    preHeadingText,
    postHeadingText,
    image,
    display,
    legalText = 'Terms & Conditions apply.',
  }: PropsI = props;
  if (!display) return null;

  return (
    <Background>
      <Container className="container">
        <PreHeading>{fromMarkdown({ text: preHeadingText, isHeading: false })}</PreHeading>
        <H1>{mainHeading}</H1>
        <PostHeading
          dangerouslySetInnerHTML={{
            __html: replacePlaceholderWithTags(postHeadingText, {
              tag: 'strong',
            }),
          }}
        />
        <LegalText>{legalText}</LegalText>
        <StyledImage>
          <div>
            <Image src={image.url} alt={image.alternativeText} layout="fill" quality={100} />
          </div>
        </StyledImage>
        <StyledPostcodeSearch>
          <PostcodeSearch isStandalone={false} />
        </StyledPostcodeSearch>
      </Container>
    </Background>
  );
};
