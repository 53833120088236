import React from 'react';
import { VideoRelay as Molecule } from '@components/Molecules';

export const VideoRelay = (props) => {
  return (
    <>
      <Molecule {...props} />
    </>
  );
};
