import React, { FC } from 'react';

import { ButtonType } from '@components/Atoms/Button/types';
import { InputType } from '@components/Atoms/Input/types';

import { OutageFormForm as Molecule } from '@components/Molecules';

export const OutageForm: FC<any> = ({
  outageCheckText,
  conditionsText,
  successHeader,
  successMessage,
  errorMessage,
}) => {
  const outageFormData = {
    conditionsText: conditionsText,
    successHeader: successHeader,
    successMessage: successMessage,
    errorMessage: errorMessage,
    name: {
      label: 'Name',
      error: 'Please enter your name',
      type: InputType.TEXT,
      fieldName: 'name',
    },
    surname: {
      label: 'Surname',
      error: 'Please enter your surname',
      type: InputType.TEXT,
      fieldName: 'surname',
    },
    email: {
      label: 'Email address*',
      error: 'Please enter a valid email address',
      type: InputType.EMAIL,
      fieldName: 'email',
    },
    postcode: {
      label: 'Postcode',
      error: 'Please enter your postcode',
      type: InputType.TEXT,
      fieldName: 'postcode',
    },
    checkbox: {
      value: 'Checkbox',
      name: 'accept_terms',
      id: 'accept_terms',
      error: 'Please accept the terms & conditions',
      label: outageCheckText,
    },
    submitButton: {
      display: true,
      link: '',
      label: 'Submit',
      buttonType: ButtonType.PRIMARY,
    },
  };

  return (
    <section>
      <Molecule data={outageFormData} />
    </section>
  );
};
