import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';

export default styled.div`
@media ${breakpoints.tablet} {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50%;
    background-color: #e5e5ff;
  }
`;
