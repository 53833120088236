import { mapFieldColorToVar } from '@lib/utils/mapFieldColorToVar';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const Heading = styled.h1<{ color: string; as?: string }>`
  color: ${({ color }) => mapFieldColorToVar(color || '')};

  ${(props) => {
    if (!props.as || props.as === 'h1') {
      return `
        font-size: calc(2rem + 2vw);
        line-height: 1.05;
        font-weight: 500;
        max-width: clamp(12rem, 75%, 18rem);
        @media ${breakpoints.mobilelg} {
            max-width: clamp(12rem, 75%, 24rem);
        }
        @media ${breakpoints.tablet} {
            max-width: clamp(12rem, 75%, 26rem);
        }
        @media ${breakpoints.desktop} {
            max-width: clamp(12rem, 92%, 95%);
        }
      `;
    }

    if (props.as === 'h2') {
      return `
        font-size: calc(1.1rem + 2vw);
        line-height: 1;
        font-weight: 500;
        margin: 1rem 0;
        @media ${breakpoints.desktop} {
        font-size: calc(1.1rem + 1vw);
        margin: .75rem 0 1rem;
        }
      `;
    }
  }}
`;
