import { Button, Icon } from '@components/Atoms';
import { ButtonType } from '@components/Atoms/Button/types';
import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import UploadIcon from '@public/icons/upload.svg';
import DeleteIcon from '@public/icons/Close-red.svg';

const STYLED = {
  landingZone: styled.div<{ isDragActive: boolean }>`
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px dashed rgba(85, 81, 96, 0.4);
    border-radius: 0.5rem;
    margin: 1rem 0;
    background: var(--color-lightest-blue);
    position: relative;

    ${(props) =>
      props.isDragActive &&
      `
        border-color: rgba(85, 81, 96, 0.8);
        `}

    & > div {
      transform: scale(1);
      transition: transform 0.2s ease-in-out;
      ${(props) =>
        props.isDragActive &&
        `
              transform: scale(1.1);
          `}
    }

    div,
    p {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    p {
      font-family: var(--font-geomanist);
      color: black;

      div {
        font-weight: 300;
        font-size: 1.25rem;
        margin-bottom: 1rem;
        &:first-of-type {
          font-weight: 500;
          margin-bottom: 0;
        }
      }
    }
  `,
  fileItem: styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;
    margin-bottom: 0.25rem;

    button {
      margin-top: -0.25rem;
      width: 1rem;
      img {
        height: 1rem;
        min-width: 1rem;
      }
    }
  `,
  notification: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background: rgba(0, 0, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    z-index: 1;
    color: white;
    font-family: var(--font-geomanist);
    font-weight: 500;
    font-size: 2rem;
  `,
  label: styled.div`
    margin-top: 1.5rem;
    & > p {
      font-family: var(--font-geomanist);
      color: black;
      font-weight: 500;
      font-size: 1.25rem;
      margin-bottom: 0;
      span {
        font-weight: 300;
        font-style: italic;
      }
    }
    & > small {
      display: block;
      margin-top: -0.25rem;
      font-family: var(--font-geomanist);
      color: black;
      font-weight: 300;
      font-size: 1rem;
    }
  `,
  files: styled.div`
    p {
      color: black;
      font-weight: 500;
      font-family: var(--font-geomanist);
    }
  `,
  error: styled.p`
    color: red;
    font-family: var(--font-geomanist);
    font-weight: 500;
    font-size: 1rem;
    margin-top: 1rem;
  `,
};

function FileUpload() {
  const [notification, setShowNotification] = React.useState('');
  const [error, setError] = React.useState('');
  const [files, setFiles] = React.useState<File[]>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const twoMB = 2097152;
    const validFiles = acceptedFiles.filter((file) => file.size <= twoMB);
    setError('');

    if (validFiles.length !== acceptedFiles.length) {
      setError('One or more files were rejected as they are too big');
    }

    if (validFiles.length) {
      setShowNotification(`File${validFiles.length > 1 ? 's' : ''} added`);
    }

    setFiles((prevFiles: File[]) => {
      const newFiles = validFiles.filter((file) => !prevFiles.includes(file));

      return [...prevFiles, ...newFiles];
    });

    setTimeout(() => {
      setShowNotification('');
    }, 2000);

    // Do something with the files
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 2,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'application/pdf': ['.pdf'],
    },
  });

  const removeFile = useCallback(
    (e, file) => {
      e.preventDefault();
      setFiles((prevFiles: File[]) => {
        return prevFiles.filter((f) => f !== file);
      });
    },
    [setFiles],
  );

  const acceptedFileItems = files.map((file, i) => {
    const mbs = (file.size / 1024 / 1024).toFixed(2);

    return (
      <STYLED.fileItem key={i}>
        {file.name} - {mbs}MB
        <button onClick={(e) => removeFile(e, file)} type="button">
          <Icon icon={DeleteIcon} xs />
          <span className="sr-only">Remove file</span>
        </button>
        <input
          type="file"
          name={`file_upload[${i}]`}
          style={{
            display: 'none',
          }}
        />
      </STYLED.fileItem>
    );
  });

  useEffect(() => {
    // eslint-disable-next-line no-console
    files.forEach((file, i) => {
      const fileInput = document.querySelector(`input[name="file_upload[${i}]`) as HTMLInputElement;

      // Create a data transfer object. Similar to what you get from a `drop` event as `event.dataTransfer`
      const dataTransfer = new DataTransfer();

      // Add your file to the file list of the object
      dataTransfer.items.add(file);

      fileInput.files = dataTransfer.files;
    });
  }, [files, acceptedFileItems]);

  return (
    <>
      <STYLED.label>
        <p>
          Attach documents <span>(Optional)</span>
        </p>
        <small>
          Files must be JPG, PNG or PDF and must not be bigger than 2MB.{' '}
          <div>There is a maximum of 8 files allowed.</div>
        </small>
      </STYLED.label>
      <STYLED.landingZone {...getRootProps()} isDragActive={isDragActive}>
        {notification && <STYLED.notification>{notification}</STYLED.notification>}
        <input {...getInputProps()} name="file_upload" />
        <div>
          <Icon icon={UploadIcon} />
          <p>
            {isDragActive ? (
              <div>Drop your files here</div>
            ) : (
              <div>Drag and drop your files here</div>
            )}

            <div>Or</div>
            <Button as="div" buttonType={ButtonType.SECONDARY}>
              Select your files
            </Button>
          </p>
        </div>
      </STYLED.landingZone>
      {error && <STYLED.error>{error}</STYLED.error>}
      {!!acceptedFileItems.length && (
        <STYLED.files>
          <p>Files</p>
          <ul>{acceptedFileItems}</ul>{' '}
        </STYLED.files>
      )}
    </>
  );
}

export default FileUpload;
