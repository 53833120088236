import styled from 'styled-components';

export default styled.div`
  position: absolute;
  top: -3.5rem;
  width: 100%;
  height: 50%;
  background-color: #e5e5ff;
  z-index: -1;
`;
