import React, { FC } from 'react';
import { TwoColumnText as Molecule } from '@components/Molecules';

export const TwoColumnText = (props) => {
  return (
    <>
      <Molecule {...props} />
    </>
  );
};
