import { SpeedTestData } from '../types';

export const mockSpeedTestData: SpeedTestData = {
  heading: 'Take the speed test',
  body: 'Check out the time difference in download speeds! Our average wired speed of 900Mbps is up to 50x faster than the UK’s average. How much time would you save each day?',
  smallPrint:
    '*Broadband speed calculations: The UK’s average broadband speeds are 80.2Mbps for downloads and 21.6Mbps for uploads, compared to Hey!Broadband’s top average speed of 900Mbps which is achieved through a wired connection. Average speeds taken from Ofcom’s ‘UK Home Broadband Performance’ measurement period May 2021.',
  dropdown: [
    { label: 'Select an example to start' },
    { label: 'Download 500 songs from Spotify (2.5GB)' },
    { label: 'Download HD Movie (4GB)' },
    { label: 'Upload 100 photos to Facebook (300MB)' },
  ],
  cta: {
    label1: 'Start',
    label2: 'Reset',
  },
  speedChart: {
    label1: 'H!B, 1000 Mbps',
    label2: 'Others, 50 Mbps',
  },
};
