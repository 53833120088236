import React, { FC } from 'react';
import { PackagesPromoT } from './types';
import { PackagesPromoBlock as Organism } from '@components/Organisms';

export const PackagesPromo: FC<PackagesPromoT> = ({ heading, CTA, PackageCard, colorTheme }) => {
  return (
    <Organism
      title={heading}
      ctaText={CTA.label}
      ctaDisplay={CTA.display}
      blocks={PackageCard.map((card: { heading: string }) => ({
        title: card.heading,
        ...card,
      }))}
      href={CTA.link || '/advantages'}
      colorTheme={colorTheme}
    />
  );
};
