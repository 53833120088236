import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export default styled.div`
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media (${breakpoints.tablet}) {
    max-width: ${rem(1200)};
    margin: 0 auto;
  }
`;
