import React from 'react';
import { HeroWithCards as Molecule } from '@components/Molecules';

export const HeroWithCards = (props) => {
  return (
    <>
      <Molecule {...props} />
    </>
  );
};
