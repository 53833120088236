import React, { FC } from 'react';

import { MediaBlock as Organism } from '@components/Organisms';

export const Media: FC<any> = ({
  heading,
  body,
  mediaType,
  media,
  cta,
  showWaves = false,
  Image,
  blueBackground,
  blueHeading,
}) => {
  const data = {
    heading,
    body,
    mediaType,
    media,
    cta,
    showWaves,
    image: Image,
    blueBackground,
    blueHeading,
  };

  return <Organism data={data} />;
};
