import React from 'react';
import { LargeInfoCard as Molecule } from '@components/Molecules';

export const LargeInfoCard = (props) => {
  return (
    <>
      <Molecule
        {...props}
      />
    </>
  );
};