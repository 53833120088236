import React, { FC } from 'react';

import { TextElementListT } from './types';
import { TextElementList as Molecule } from '@components/Molecules';

export const TextElementList: FC<TextElementListT> = ({
  display,
  heading,
  TextElements,
  listStyle,
  image,
}) => {
  return display ? (
    <Molecule heading={heading} textElements={TextElements} listStyle={listStyle} image={image} />
  ) : null;
};
