import { mapFieldColorToVar } from '@lib/utils/mapFieldColorToVar';
import styled from 'styled-components';

export const Text = styled.div<{ color: string }>`
  color: ${({ color }) => mapFieldColorToVar(color || '')};
  margin-bottom: 1rem;

  & * {
    color: inherit;
  }
`;
