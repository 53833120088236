import React, { useCallback, FC, useState } from 'react';
import { CheckoutFormRow } from './CheckoutFormRow';
import StyledCheckoutCheckbox from './styled/StyledCheckoutCheckbox';
import { Button, CheckBox, Spinner } from '@components/Atoms';
import { renderParagraphs } from '@lib/utils';
import StyledButtonRow from './styled/StyledButtonRow';
import { ButtonType } from '@components/Atoms/Button/types';
import { StyledErrorBar } from '@components/Atoms/Form';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import StyledCheckoutFormRow from './styled/StyledCheckoutFormRow';
import { Dropdown } from '@components/Molecules';
import { init, send } from '@emailjs/browser';

interface PropsI {
  howWeUseYourDataText?: string;
  onSuccess: () => void;
  onError: () => void;
}

interface FieldI {
  key: string;
  label?: string;
  type?: string;
  required?: boolean;
  value?: string | Array<string>;
  options?: Array<{
    label: string;
    alias: string;
    value: string;
    checked: boolean;
  }>;
  cols?: number;
  placeholder?: string;
  preText?: JSX.Element;
}

const StyledFormGrid = styled.div`
  display: block;

  @media ${breakpoints.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 1rem;
    max-width: 800px;
  }
  [class*='StyledDropdown'] {
    background-color: white;
    font-weight: 300;
  }

  [class*='StyledCheckoutFormRow'] {
    font-size: 0.9rem;
  }
`;

const StyledFormGridItem = styled.div<{ cols: number | undefined }>`
  grid-column: span ${({ cols }) => cols};

  *:not(.booleanInput) input,
  *:not(.booleanInput) textarea {
    background-color: white;
  }
  &:not(.booleanInput) textarea {
    height: 12rem;
    &::placeholder {
      font-size: 0.75rem;
      color: var(--colors-grey-30);
    }
  }
  * {
    font-weight: 300;
  }
`;

const contactTitles = [
  { value: 'DR', label: 'Dr.' },
  { value: 'MISS', label: 'Miss.' },
  { value: 'MR', label: 'Mr.' },
  { value: 'MRS', label: 'Mrs.' },
  { value: 'MS', label: 'Ms.' },
  { value: 'SIR', label: 'Sir' },
];

init('user_kpWsSBhCezkaPPptoqvqP');

export const VulnerabilityProcessForm: FC<PropsI> = ({ howWeUseYourDataText, onSuccess }) => {
  const fields: Array<FieldI> = [
    { key: 'contact_name', label: 'First Name', required: true, value: '', cols: 1 },
    { key: 'contact_surname', label: 'Last Name', required: true, value: '', cols: 1 },
    { key: 'email', label: 'Email Address', required: true, value: '', cols: 1 },
    { key: 'phone', label: 'Phone Number', required: true, value: '', cols: 1 },
    { key: 'address', label: 'Address', required: true, value: '', cols: 1 },
    { key: 'postcode', label: 'Postcode', required: true, value: '', cols: 1 },
    {
      key: 'needs',
      label: 'Detail',
      type: 'textarea',
      required: true,
      value: '',
      cols: 2,
      preText: (
        <div className="mt-4 mb-2">
          <p className="text-black font-geomanist">
            Please provide us with more detail, for example:
          </p>
          <ul className="list-disc pl-6 font-geomanist mt-1">
            <li className="text-sm">register vulnerabilities</li>
            <li className="text-sm">request third party account management</li>
            <li className="text-sm">let us know of any accessibility requirements</li>
            <li className="text-sm">
              let us know if you are going through a period of difficulty due to a situation
            </li>
          </ul>
        </div>
      ),
    },
    {
      key: 'accept_terms',
      type: 'checkbox',
      required: true,
      value: '',
    },
  ];

  const defaultFormData = fields.reduce((acc, field) => {
    return {
      ...acc,
      [field.key]: field.value,
    };
  }, {});

  const [formData, setFormData] = useState<any>(defaultFormData);

  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const formDataValues: Array<{
    [key: string]: string;
  }> = Object.values(formData);

  const validators = [
    {
      keys: [],
      isValid: formDataValues.every((field) => field),
      message: 'Field is required',
    },
    {
      keys: ['contact_title'],
      isValid:
        formData.contact_title &&
        ['DR', 'MISS', 'MRS', 'MR', 'MRS', 'MS', 'SIR'].indexOf(
          formData?.contact_title?.value || '',
        ) > -1,
      message: 'Please enter a valid title',
    },
    {
      keys: ['email'],
      isValid: formData.email?.match(/^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/),
      message: 'Email is invalid',
    },
    {
      keys: ['postcode'],
      isValid: formData.postcode?.match(/^([A-Z]{1,2}[0-9][0-9A-Z]?)\s?([0-9][A-Z]{2})$/i),
      message: 'Postcode is invalid',
    },
    {
      keys: ['phone'],
      isValid: formData.phone?.match(
        /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/,
      ),
      message: 'Phone is invalid',
    },
    {
      keys: ['needs'],
      isValid: formData.needs?.length > 0,
      message: 'Please tell us your needs',
    },
  ];

  const isValid = validators.every((validator) => validator.isValid);
  const errorOnSubmit = hasSubmitted && validators.some((validator) => !validator.isValid);

  const updateFormData = (key: string, value: any) => {
    setFormData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const submitForm = useCallback(
    (event) => {
      event?.preventDefault();
      // Hit the API
      const service = process.env.NEXT_PUBLIC_EMAIL_SERVICE || 'service_2cgv65g';
      const user = process.env.NEXT_PUBLIC_EMAIL_SERVICE_USER;

      const data = {
        to_name: 'Special Assist Team',
        from_name: `${formData.contact_name} ${formData.contact_surname}`,
        ...formData,
        contact_title: formData.contact_title?.label,
      };

      send(service, 'template_wz9mkj7', data, user);

      setLoading(true);

      setTimeout(() => {
        setHasSubmitted(true);
        onSuccess();
        setLoading(false);
      }, 1000);
    },
    [formData],
  );

  if (loading) {
    return <Spinner />;
  }

  return hasSubmitted && !errorOnSubmit ? (
    <p>Thank you for registering your needs, we will be in touch.</p>
  ) : (
    <form onSubmit={submitForm} className="flex flex-col items-center">
      <StyledFormGrid>
        <StyledCheckoutFormRow>
          <Dropdown
            items={contactTitles}
            id="contact_title"
            placeholderLabel="Title"
            defaultSelectedItem={formData.contact_title}
            onChange={(state: { value: string }) =>
              state?.value ? updateFormData('contact_title', state) : null
            }
            required={true}
          />
        </StyledCheckoutFormRow>
        {fields
          .filter(({ type }) => type !== 'checkbox')
          .map(({ key, label, type, required, options, cols, placeholder, preText }: FieldI) => (
            <StyledFormGridItem cols={cols} key={key}>
              <CheckoutFormRow
                key={key}
                name={key}
                label={label}
                formData={formData}
                validators={validators}
                onChange={updateFormData}
                type={type}
                required={required}
                options={options}
                placeholder={placeholder}
                preText={preText}
              />
            </StyledFormGridItem>
          ))}
      </StyledFormGrid>
      {howWeUseYourDataText ? (
        <p className="my-4 max-w-[800px] text-left">{howWeUseYourDataText}</p>
      ) : null}
      <StyledCheckoutCheckbox>
        <CheckBox
          value="Checkbox"
          label={renderParagraphs({
            body: '<span>I agree to the [Terms & Conditions](/legal)</span>',
            hasMarkdown: true,
          })}
          name="accept_terms"
          id="accept_terms"
          checked={formData.accept_terms}
          onClick={(value) => updateFormData('accept_terms', value)}
        />
      </StyledCheckoutCheckbox>
      {errorOnSubmit ? (
        <StyledErrorBar>
          There was an error submitting your details. Please try again.
        </StyledErrorBar>
      ) : null}
      <StyledButtonRow className="mt-10">
        <Button buttonType={ButtonType.PRIMARY} type="submit" disabled={!isValid}>
          Submit
        </Button>
      </StyledButtonRow>
    </form>
  );
};
