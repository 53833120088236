import { Button } from '@components/Atoms';
import { ButtonType } from '@components/Atoms/Button/types';
import { CtaT } from '@components/Molecules/Cta/types';
import { PostcodeSearch } from '@components/Molecules/PostcodeSearch';
import usePostcodeContext from '@hooks/usePostcodeContext';
import { Format, ImageDataT, MDUStatus, PageType, UprnStatus } from '@lib/types';
import { getSlug, renderImage } from '@lib/utils';
import { breakpoints } from '@theme/breakpoints';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import * as STYLED from './styled';
import {
  Postcode,
  SERVICE_PREORDER,
  SERVICE_UNAVAILABLE,
} from '@components/Organisms/Postcode/Postcode';
import { useRouter } from 'next/router';
import { getIsCurrentPostcodePreorder } from '@lib/utils/uprn';

const StyledDesktopImage = styled.div<{
  imagePosition?: string;
  imageScaleMode?: string;
}>`
  display: none;

  @media ${breakpoints.tablet} {
    display: block;
    img {
      mix-blend-mode: multiply;
    }
  }

  @media ${breakpoints.desktopLarge} {
    img {
      object-fit: ${({ imageScaleMode }) =>
        imageScaleMode ? imageScaleMode.replace('_', '-') : 'contain'};
      object-position: ${({ imagePosition }) =>
        imagePosition ? imagePosition.replace('_', ' ') : 'center center'};
    }
  }
`;

const StyledMobileImage = styled.div`
  display: block;

  @media ${breakpoints.tablet} {
    display: none;
  }
`;

export const CoverageSingleHero = ({
  display,
  heading,
  addressEnteredSubHeading,
  addressNotEnteredSubHeading,
  headingColor,
  subheadingColor,
  descriptionColor,
  addressEnteredText,
  addressNotEnteredText,
  image,
  imageMobile,
  imagePosition,
  imageScaleMode,
  cta,
}) => {
  const { isCurrentPostcodeEligible, setHasConfirmedAddress, setPostcodeItem, clearPostcode } =
    usePostcodeContext();

  const router = useRouter();

  const subHeadingToShow = isCurrentPostcodeEligible
    ? addressEnteredSubHeading
    : addressNotEnteredSubHeading;
  const textToShow = isCurrentPostcodeEligible ? addressEnteredText : addressNotEnteredText;

  const handlePostcodeSubmit = useCallback(
    (data) => {
      const { coverage } = data;
      if (coverage === UprnStatus.CUSTOMER) {
        clearPostcode();
        return router.push('/error/existing-customer');
      }

      const ismdu = data.ismdu;
      const mdu_status = data.mdu_status;
      const isPreorder =
        getIsCurrentPostcodePreorder(data) || (ismdu && mdu_status !== MDUStatus.RFS);

      if (
        SERVICE_UNAVAILABLE.includes(coverage) ||
        SERVICE_PREORDER.includes(coverage) ||
        isPreorder
      ) {
        // show not available message
        return router.push('/service-unavailable');
      }
      setPostcodeItem(data);
      setHasConfirmedAddress(true);
    },
    [router, setHasConfirmedAddress, setPostcodeItem, clearPostcode],
  );

  if (!display) return null;

  return (
    <section className="md:container">
      <STYLED.Wrapper>
        <div>
          <header>
            <STYLED.Heading color={headingColor}>{heading}</STYLED.Heading>
            <STYLED.Heading as="h2" color={subheadingColor}>
              {subHeadingToShow}
            </STYLED.Heading>
          </header>
          <div>
            <STYLED.Text color={descriptionColor}>{textToShow}</STYLED.Text>
          </div>
          {!isCurrentPostcodeEligible ? (
            <Postcode
              showTitle={false}
              inline={true}
              onSubmit={handlePostcodeSubmit}
              placeholder="Enter your postcode to find out!"
              shouldRedirect={false}
            />
          ) : (
            <Button as="a" href={cta.link} buttonType={ButtonType.PRIMARY}>
              {cta.label}
            </Button>
          )}
        </div>
        <div>
          <StyledDesktopImage imagePosition={imagePosition} imageScaleMode={imageScaleMode}>
            {image &&
              renderImage({
                alternativeText: image.alternativeText,
                formats: image.formats,
                format: Format.LARGE,
              })}
          </StyledDesktopImage>

          <StyledMobileImage>
            {imageMobile &&
              renderImage({
                className: '',
                alternativeText: imageMobile.alternativeText,
                formats: imageMobile.formats,
                format: Format.LARGE,
              })}
          </StyledMobileImage>
        </div>
      </STYLED.Wrapper>
    </section>
  );
};
