import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 2rem;
  padding: 1rem 1rem 2rem;

  @media ${breakpoints.tablet} {
    padding-left: 0;
    padding-right: 0;
  }

  @media ${breakpoints.desktop} {
    padding: 3rem 0;
  }

  @media ${breakpoints.desktop} {
    gap: 4rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & :first-child {
      flex: 1;
    }

    & :last-child {
      flex: 1;
    }
  }
`;
