import { CopyToClipboard } from '@components/Atoms/CopyToClipboard/CopyToClipboard';
import { Format, ImageT } from '@lib/types';
import { renderImage } from '@lib/utils';
import { breakpoints } from '@theme/breakpoints';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.section`
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;

  @media ${breakpoints.tablet} {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .promo-offer-block {
    &__title {
      color: var(--color-primary);
      font-size: 2.5rem;
      font-weight: 500;
      line-height: 1.1;

      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
      @media ${breakpoints.tablet} {
        margin-bottom: 0;
        margin-top: 0;
        font-size: 3.5rem;
      }
      @media ${breakpoints.desktop} {
        font-size: 4.25rem;
      }
    }
    &__subtitle {
      color: black;
      font-family: var(--font-geomanist);
      font-weight: 600;
      margin-bottom: 0.5rem;
      @media ${breakpoints.mobilelg} {
        font-size: 1.25rem;
      }

      @media ${breakpoints.tablet} {
        font-size: 1.25rem;
        font-weight: 500;
      }
      @media ${breakpoints.desktop} {
        font-size: 1.75rem;
      }
    }
    &__promocode {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.1rem;
      small {
        font-weight: 300;
        font-family: var(--font-geomanist);
      }
      @media ${breakpoints.tablet} {
        flex-direction: row;
        gap: 0.5rem;

        small {
          font-size: 1rem;
        }
      }
    }

    &__text-content {
      @media ${breakpoints.desktop} {
        max-width: 40rem;
        padding-right: 2vw;
      }
    }
  }
`;

const Title = ({ title }) => {
  return <h2 className="promo-offer-block__title">{title}</h2>;
};

const SubTitle = ({ subtitle }) => {
  return <p className="promo-offer-block__subtitle">{subtitle}</p>;
};

const TextContent = (props) => (
  <div className="promo-offer-block__text-content">
    <Title {...props} />
    <SubTitle {...props} />
    <PromoCode {...props} />
  </div>
);

const StyledCode = styled.div`
  background: linear-gradient(90deg, #0402fd, #f4111b 105%);
  color: white;
  font-weight: 500;
  font-size: 1.5rem;
  font-family: var(--font-geomanist);
  padding: 0.4rem 1rem 0.2rem;
  border-radius: 0.75rem;
`;

const PromoCode = ({ promoCode }) => {
  return (
    <div className="promo-offer-block__promocode">
      <small>Use code</small>
      <CopyToClipboard text={promoCode}>
        <StyledCode>{promoCode}</StyledCode>
      </CopyToClipboard>
      <small>at checkout</small>
    </div>
  );
};

const Image = ({ image }) => {
  return renderImage({
    className: `promo-offer-block__image`,
    alternativeText: image.alternativeText,
    formats: image.formats,
    format: Format.LARGE,
  });
};

export const PromoOfferBlock = (props) => {
  const [isMobile, setIsMobile] = useState<null | true | false>(null);

  const [JSXOrder, setJSXOrder] = useState([Title, SubTitle, PromoCode, Image]);

  useEffect(() => {
    setIsMobile(window.matchMedia('screen and (max-width: 767px)').matches);
  }, []);

  useEffect(() => {
    const resizeHandler = () => {
      setIsMobile(window.matchMedia('screen and (max-width: 767px)').matches);
    };

    window.addEventListener('resize', resizeHandler);

    setJSXOrder(isMobile ? [Image, TextContent] : [TextContent, Image]);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [isMobile]);

  if (isMobile === null) {
    return null;
  }

  return (
    <div className="container">
      <StyledWrapper>
        {JSXOrder.map((Component, index) => (
          <Component key={index} {...props} />
        ))}
      </StyledWrapper>
    </div>
  );
};
