import React, { FC } from 'react';
import { FullLengthCardBlock as Molecule } from '@components/Molecules';

export const FullLengthCardBlock = (props) => {
  return (
    <>
      <Molecule {...props} />
    </>
  );
};
