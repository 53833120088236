import React, { FC } from 'react';
import styled from 'styled-components';

interface PropsI {
  text: string;
  tagName?: string;
}

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;

  .hover-text {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 0.5rem;
    z-index: 0;

    justify-content: center;
    align-items: center;

    span {
      color: white;
      font-family: var(--font-geomanist);
      font-weight: 500;
    }
  }

  &:hover {
    .hover-text {
      display: flex;
    }
  }
`;

const Message = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  z-index: 1;

  color: black;
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: var(--font-geomanist);
`;

export const CopyToClipboard: FC<PropsI> = ({ children, text, tagName = 'span' }) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const Component = tagName as JSX.Element['type'];

  return (
    <Wrapper>
      <Component onClick={copyToClipboard}>{children || text}</Component>
      {isCopied && <Message>Copied</Message>}
      {!isCopied && (
        <button onClick={copyToClipboard} className="hover-text">
          <span>Click to copy</span>
        </button>
      )}
    </Wrapper>
  );
};
