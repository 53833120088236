import React, { FC } from 'react';

import { ButtonType } from '@components/Atoms/Button/types';
import { InputType } from '@components/Atoms/Input/types';

import { ContactForm as Molecule } from '@components/Molecules';
import { ContactCardList as Organism } from '@components/Organisms';

export const Contact: FC<any> = ({
  heading,
  formHeading,
  formImage,
  ContactCards,
  displayMessage,
  displayCity,
  displayAddress,
  displayPostcode,
  displayBirthdate,
  emailTemplate,
  checkboxText,
}) => {
  const contactFormData = {
    formHeading: formHeading,
    formImage: formImage,
    name: {
      label: 'Full Name*',
      error: 'Please enter your full name',
      type: InputType.TEXT,
      fieldName: 'name',
    },
    phone: {
      label: 'Phone number*',
      error: 'Please enter a valid telephone number',
      type: InputType.TEXT,
      fieldName: 'telephone',
    },
    city: {
      label: 'City*',
      error: 'Please enter your home city',
      type: InputType.TEXT,
      fieldName: 'city',
      display: displayCity,
    },
    email: {
      label: 'Email address*',
      error: 'Please enter a valid email address',
      type: InputType.EMAIL,
      fieldName: 'email',
    },
    message: {
      label: 'Type Message*',
      error: 'Please add your message here',
      textArea: true,
      type: InputType.TEXTAREA,
      fieldName: 'message',
      display: displayMessage,
    },
    checkbox: {
      value: 'Checkbox',
      name: 'accept_terms',
      id: 'accept_terms',
      error: 'Please accept the terms & conditions',
      label: '',
    },
    successMessage: 'Thank you for your message! A member of our team will be in touch shortly.',
    errorMessage: 'There was an error trying to send your message. Please try again later.',
    submitButton: {
      display: true,
      link: '',
      label: 'Send message',
      buttonType: ButtonType.PRIMARY,
    },
    address: {
      label: 'Address*',
      error: 'Please enter your address',
      type: InputType.TEXT,
      fieldName: 'address',
      display: displayAddress,
    },
    postcode: {
      label: 'Postcode*',
      error: 'Please enter your postcode',
      type: InputType.TEXT,
      fieldName: 'postcode',
      display: displayPostcode,
    },
    birthdate: {
      label: 'Date of birth*',
      error: 'Please enter your date of birth',
      type: InputType.DATE,
      fieldName: 'birthdate',
      display: displayBirthdate,
    },
    emailTemplate: emailTemplate,
    checkboxSubscription: {
      value: 'Subscribed',
      name: 'checkbox_subscription',
      id: 'checkbox_subscription',
      label: checkboxText,
    },
  };

  const contactCardListData = {
    heading,
    contactCards: ContactCards,
  };

  return (
    <section className="cm-contact-form">
      <Organism data={contactCardListData} />
      <Molecule data={contactFormData} />
    </section>
  );
};
