import React, { FC, useState } from 'react';
import StyledContainer from './styled/StyledContainer';
import StyledVulnerabilityProcessForm from './styled/StyledVulnerabilityProcessForm';
import { Heading } from '@components/Atoms/Heading';
import { VulnerabilityProcessForm as Form } from '../../../../components/Organisms/OrderForms/VulnerabilityProcessForm';
import { PostcodePopup } from '@components/Organisms';

export const VulnerabilityProcessForm: FC<{
  Title: string;
  howWeUseYourDataText: string;
  display: boolean;
}> = ({ Title, howWeUseYourDataText, display }) => {
  const [title, onSubmit] = useState('Register your needs via the form below');

  if (!display) {
    return null;
  }

  return (
    <StyledVulnerabilityProcessForm id="form">
      <StyledContainer>
        <header className="mb-10">
          <Heading level={2}>{title || Title}</Heading>
        </header>
        <Form
          howWeUseYourDataText={howWeUseYourDataText}
          onSuccess={() => onSubmit('Success!')}
          onError={() => onSubmit('Oops! Something went wrong.')}
        />
      </StyledContainer>
    </StyledVulnerabilityProcessForm>
  );
};
