import React, { FC } from 'react';
import { TestT } from './types';
import { Format, Layout } from '@lib/types';
import { renderParagraphs, renderImage } from '@lib/utils';

export const Test: FC<TestT> = ({
  display,
  subHeading,
  heading,
  body,
  timestamp,
  amount,
  option,
  imageList,
  image,
}) => {
  if (!display) return <></>;

  return (
    <>
      <h1>{heading}</h1>
      <h5>{subHeading}</h5>

      <br />

      <div>
        <p>{timestamp}</p>
        <p>{amount}</p>
      </div>

      <br />

      <div>
        <select name="test-select-box" value={option}>
          <option value="foo">Foo</option>
          <option value="bar">Bar</option>
          <option value="baz">Baz</option>
        </select>
      </div>

      <br />

      {body && renderParagraphs({ body })}

      <br />

      {image && renderImage({ ...image, format: Format.LARGE, layout: Layout.FIXED })}

      <br />

      {imageList?.map(({ alternativeText, formats }) =>
        renderImage({ alternativeText, format: Format.SMALL, formats, layout: Layout.FIXED }),
      )}
    </>
  );
};
