import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Image from 'next/image';
import { CtaT } from '@components/Molecules/Cta/types';
import { Button } from '@components/Atoms';
import { ButtonType } from '@components/Atoms/Button/types';
import Link from 'next/link';
import Checkbox from '@public/icons/Checkbox-Gradient.png';
import BennyPointing from '@public/benny-pointing-up-lg.png';

const STYLED = {
  container: styled.div`
    position: relative;
    overflow: hidden;
    @media ${breakpoints.mobilelg} {
      overflow: visible;
    }
  `,
  benny: styled.div`
    position: absolute;
    bottom: -3rem;
    right: 0.5rem;
    z-index: 1;
    width: 8rem;

    @media (min-width: 425px) {
      width: 10rem;
      right: 1rem;
    }
    @media ${breakpoints.mobilelg} {
      width: 12rem;
      right: 2rem;
      bottom: -2.25rem;
    }
    @media ${breakpoints.tablet} {
      bottom: -2.6rem;
      right: 55%;
      width: 14rem;
    }
    @media ${breakpoints.desktopLarge} {
      width: 16rem;
      bottom: -2.9rem;
    }
  `,
  wrapper: styled.article`
    padding: 0 0 6rem;

    @media ${breakpoints.tablet} {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 2rem;
      padding: 4rem 4rem 6rem;

      & > * {
        flex: 1;
      }
    }

    @media ${breakpoints.desktop} {
      gap: 4rem;
    }

    @media ${breakpoints.desktopLarge} {
      max-width: 80%;
      margin: 0 auto;
      padding: 4rem 4rem 8rem;
    }
  `,
  heading: styled.h2`
    font-size: 1.75rem;
    color: var(--color-primary);
    line-height: 1.2;
    font-weight: 500;
    @media ${breakpoints.desktop} {
      font-size: 2.25rem;
    }
  `,
  list: styled.ul`
    list-style: none;
    padding: 0;
    margin: 2rem 0;
    @media ${breakpoints.tablet} {
      margin-top: 0;
    }
    li {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-family: var(--font-geomanist);
      font-weight: 600;
      font-size: 1.25rem;
      color: black;

      &::before {
        display: inline-block;
        content: '';
        height: 2rem;
        width: 2rem;
        min-width: 2rem;
        background-image: url(${Checkbox.src});
        background-size: 90%;
        background-repeat: no-repeat;
        left: 0;
      }
    }
  `,
};

interface PropsI {
  display: boolean;
  heading: string;
  list: {
    id: string;
    text: string;
  }[];
  cta: CtaT;
}

export const TickList = (props) => {
  const { display, heading, list, cta }: PropsI = props;

  if (!display) return null;
  return (
    <STYLED.container className="container">
      <STYLED.benny>
        <Image src={BennyPointing} alt="Benny pointing up" />
      </STYLED.benny>
      <STYLED.wrapper>
        <div>
          <STYLED.heading>{heading}</STYLED.heading>
        </div>
        <div>
          <STYLED.list>
            {list.map((item) => (
              <li key={item.id}>{item.text}</li>
            ))}
          </STYLED.list>
          {!!cta && !!cta.link && (
            <Link passHref href={cta.link}>
              <Button as="a" buttonType={ButtonType.PRIMARY}>
                {cta.label}
              </Button>
            </Link>
          )}
        </div>
      </STYLED.wrapper>
    </STYLED.container>
  );
};
