import React, { FC } from 'react';

import { SpeedTestBlock as Organism } from '@components/Organisms';
import { mockSpeedTestData } from '@components/Organisms/SpeedTestBlock/__mockData__';

export const SpeedTest: FC<any> = ({ heading, body, smallPrint, dropdown, cta, speedChart }) => {
  return (
    <Organism
      data={{
        ...mockSpeedTestData,
        heading,
        body,
        smallPrint,
        // dropdown,
        // speedChart,
      }}
    />
  );
};
