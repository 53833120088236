import { HeroData } from '../types';
import { ButtonType } from '@components/Atoms/Button/types';
import { mockTrustBoxData } from '@components/Atoms/TrustBox/__mockData__';

export const mockHeroHomepageLandingData: HeroData = {
  heroType: 'heroMain',
  headline: 'Real Full Fibre Broadband',
  description:
    'Get the fastest, most reliable and affordable 100% full fibre broadband directly to your home. From only £23 per month. ',
  postcodeLabel: 'Get started. Check availability',
  callToAction: {
    label: 'Get started. Check availability',
    type: ButtonType.SECONDARY,
  },
  featureList: {
    list: [
      { name: '100% Fibre to your home' },
      { name: 'Fixed price' },
      { name: 'Zero commitment option' },
      { name: 'Wi-Fi 6 enabled' },
    ],
  },
  trustpilot: mockTrustBoxData,
  mobileImage: {
    id: 10,
    name: 'dummy--1920x1080.png',
    alternativeText: '',
    caption: '',
    width: 1920,
    height: 1080,
    formats: {
      large: {
        ext: '.png',
        url: '/uploads/large_dummy_1920x1080_e5f3c306e5.png',
        hash: 'large_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'large_dummy--1920x1080.png',
        path: null,
        size: 27.16,
        width: 1000,
        height: 563,
      },
      small: {
        ext: '.png',
        url: '/uploads/small_dummy_1920x1080_e5f3c306e5.png',
        hash: 'small_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'small_dummy--1920x1080.png',
        path: null,
        size: 10.16,
        width: 500,
        height: 281,
      },
      medium: {
        ext: '.png',
        url: '/uploads/medium_dummy_1920x1080_e5f3c306e5.png',
        hash: 'medium_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'medium_dummy--1920x1080.png',
        path: null,
        size: 18.04,
        width: 750,
        height: 422,
      },
      thumbnail: {
        ext: '.png',
        url: '/uploads/thumbnail_dummy_1920x1080_e5f3c306e5.png',
        hash: 'thumbnail_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'thumbnail_dummy--1920x1080.png',
        path: null,
        size: 4.31,
        width: 245,
        height: 138,
      },
    },
    hash: 'dummy_1920x1080_e5f3c306e5',
    ext: '.png',
    mime: 'image/png',
    size: 8.38,
    url: '/uploads/dummy_1920x1080_e5f3c306e5.png',
    previewUrl: null,
    provider: 'local',
    provider_metadata: null,
    created_at: '2022-01-31T13:02:12.900Z',
    updated_at: '2022-01-31T13:02:12.918Z',
  },
  displayPostCodeChecker: true,
};

export const mockHeroLocationLandingData: HeroData = {
  heroType: 'heroMain',
  headline: 'Hey Gerrard Cross!',
  subHeadline: 'Full Fibre broadband is on its way to you',
  description:
    'Pre-order the fastest, most reliable and affordable 100% full fibre broadband directly to your home. From only £25 per month.',
  postcodeLabel: 'Get started. Check availability',
  callToAction: {
    label: 'Get started. Check availability',
    type: ButtonType.SECONDARY,
  },
  featureList: {
    list: [
      { name: '100% Fibre to your home' },
      { name: 'Fixed price' },
      { name: 'Zero commitment option' },
      { name: 'Wi-Fi 6 enabled' },
    ],
  },
  mobileImage: {
    id: 10,
    name: 'dummy--1920x1080.png',
    alternativeText: '',
    caption: '',
    width: 1920,
    height: 1080,
    formats: {
      large: {
        ext: '.png',
        url: '/uploads/large_dummy_1920x1080_e5f3c306e5.png',
        hash: 'large_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'large_dummy--1920x1080.png',
        path: null,
        size: 27.16,
        width: 1000,
        height: 563,
      },
      small: {
        ext: '.png',
        url: '/uploads/small_dummy_1920x1080_e5f3c306e5.png',
        hash: 'small_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'small_dummy--1920x1080.png',
        path: null,
        size: 10.16,
        width: 500,
        height: 281,
      },
      medium: {
        ext: '.png',
        url: '/uploads/medium_dummy_1920x1080_e5f3c306e5.png',
        hash: 'medium_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'medium_dummy--1920x1080.png',
        path: null,
        size: 18.04,
        width: 750,
        height: 422,
      },
      thumbnail: {
        ext: '.png',
        url: '/uploads/thumbnail_dummy_1920x1080_e5f3c306e5.png',
        hash: 'thumbnail_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'thumbnail_dummy--1920x1080.png',
        path: null,
        size: 4.31,
        width: 245,
        height: 138,
      },
    },
    hash: 'dummy_1920x1080_e5f3c306e5',
    ext: '.png',
    mime: 'image/png',
    size: 8.38,
    url: '/uploads/dummy_1920x1080_e5f3c306e5.png',
    previewUrl: null,
    provider: 'local',
    provider_metadata: null,
    created_at: '2022-01-31T13:02:12.900Z',
    updated_at: '2022-01-31T13:02:12.918Z',
  },
  featureImage: {
    id: 10,
    name: 'dummy--1920x1080.png',
    alternativeText: '',
    caption: '',
    width: 1920,
    height: 1080,
    formats: {
      large: {
        ext: '.png',
        url: '/uploads/large_dummy_1920x1080_e5f3c306e5.png',
        hash: 'large_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'large_dummy--1920x1080.png',
        path: null,
        size: 27.16,
        width: 1000,
        height: 563,
      },
      small: {
        ext: '.png',
        url: '/uploads/small_dummy_1920x1080_e5f3c306e5.png',
        hash: 'small_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'small_dummy--1920x1080.png',
        path: null,
        size: 10.16,
        width: 500,
        height: 281,
      },
      medium: {
        ext: '.png',
        url: '/uploads/medium_dummy_1920x1080_e5f3c306e5.png',
        hash: 'medium_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'medium_dummy--1920x1080.png',
        path: null,
        size: 18.04,
        width: 750,
        height: 422,
      },
      thumbnail: {
        ext: '.png',
        url: '/uploads/thumbnail_dummy_1920x1080_e5f3c306e5.png',
        hash: 'thumbnail_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'thumbnail_dummy--1920x1080.png',
        path: null,
        size: 4.31,
        width: 245,
        height: 138,
      },
    },
    hash: 'dummy_1920x1080_e5f3c306e5',
    ext: '.png',
    mime: 'image/png',
    size: 8.38,
    url: '/uploads/dummy_1920x1080_e5f3c306e5.png',
    previewUrl: null,
    provider: 'local',
    provider_metadata: null,
    created_at: '2022-01-31T13:02:12.900Z',
    updated_at: '2022-01-31T13:02:12.918Z',
  },
  displayPostCodeChecker: true,
};

export const mockHeroLightData: HeroData = {
  heroType: 'heroDefault',
  headline: '<b>Fast, easy and fair</b> Full Fibre broadband',
  description:
    'Full Wi-Fi coverage in every corner of your home. Enjoy easy broadband connection in every room, for every device.',
  featureImage: {
    id: 10,
    name: 'dummy--1920x1080.png',
    alternativeText: '',
    caption: '',
    width: 1920,
    height: 1080,
    formats: {
      large: {
        ext: '.png',
        url: '/uploads/large_dummy_1920x1080_e5f3c306e5.png',
        hash: 'large_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'large_dummy--1920x1080.png',
        path: null,
        size: 27.16,
        width: 1000,
        height: 563,
      },
      small: {
        ext: '.png',
        url: '/uploads/small_dummy_1920x1080_e5f3c306e5.png',
        hash: 'small_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'small_dummy--1920x1080.png',
        path: null,
        size: 10.16,
        width: 500,
        height: 281,
      },
      medium: {
        ext: '.png',
        url: '/uploads/medium_dummy_1920x1080_e5f3c306e5.png',
        hash: 'medium_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'medium_dummy--1920x1080.png',
        path: null,
        size: 18.04,
        width: 750,
        height: 422,
      },
      thumbnail: {
        ext: '.png',
        url: '/uploads/thumbnail_dummy_1920x1080_e5f3c306e5.png',
        hash: 'thumbnail_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'thumbnail_dummy--1920x1080.png',
        path: null,
        size: 4.31,
        width: 245,
        height: 138,
      },
    },
    hash: 'dummy_1920x1080_e5f3c306e5',
    ext: '.png',
    mime: 'image/png',
    size: 8.38,
    url: '/uploads/dummy_1920x1080_e5f3c306e5.png',
    previewUrl: null,
    provider: 'local',
    provider_metadata: null,
    created_at: '2022-01-31T13:02:12.900Z',
    updated_at: '2022-01-31T13:02:12.918Z',
  },
};

export const mockHeroDefaultData: HeroData = {
  heroType: 'heroDefault',
  headline: 'Our <b>COverage</b>',
  description:
    'We are currently expanding our coverage across towns in the UK. Check below our available locations and build progress.',
  featureImage: {
    id: 10,
    name: 'dummy--1920x1080.png',
    alternativeText: '',
    caption: '',
    width: 1920,
    height: 1080,
    formats: {
      large: {
        ext: '.png',
        url: '/uploads/feature_image_family_e474b241e7.png',
        hash: 'large_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'large_dummy--1920x1080.png',
        path: null,
        size: 27.16,
        width: 1000,
        height: 563,
      },
      small: {
        ext: '.png',
        url: '/uploads/feature_image_family_e474b241e7.png',
        hash: 'small_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'small_dummy--1920x1080.png',
        path: null,
        size: 10.16,
        width: 500,
        height: 281,
      },
      medium: {
        ext: '.png',
        url: '/uploads/feature_image_family_e474b241e7.png',
        hash: 'medium_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'medium_dummy--1920x1080.png',
        path: null,
        size: 18.04,
        width: 750,
        height: 422,
      },
      thumbnail: {
        ext: '.png',
        url: '/uploads/feature_image_family_e474b241e7.png',
        hash: 'thumbnail_dummy_1920x1080_e5f3c306e5',
        mime: 'image/png',
        name: 'thumbnail_dummy--1920x1080.png',
        path: null,
        size: 4.31,
        width: 245,
        height: 138,
      },
    },
    hash: 'dummy_1920x1080_e5f3c306e5',
    ext: '.png',
    mime: 'image/png',
    size: 8.38,
    url: '/uploads/feature_image_family_e474b241e7.png',
    previewUrl: null,
    provider: 'local',
    provider_metadata: null,
    created_at: '2022-01-31T13:02:12.900Z',
    updated_at: '2022-01-31T13:02:12.918Z',
  },
};

export const mockHeroServicesData: HeroData = {
  heroType: 'heroDefault',
  headline: 'Great news! We are available in your area',
  address: '1, LATCHFORD PLACE, HEMEL HEMPSTEAD, HP1 1AB',
  packageType: {
    listItems: [
      { key: 'RES', label: 'For Home' },
      { key: 'HWRK', label: 'For Home Worker' },
      { key: 'BUS', label: 'For Business' },
    ],
  },
};

export const mockHeroMSMData: HeroData = {
  heroType: 'heroMain',
  headline: 'We can’t wait to connect your home to our full fibre broadband.',
  subHeadline: 'You are nearly there.',
  address: '1, LATCHFORD PLACE, HEMEL HEMPSTEAD, HP1 1AB',
};

export const mockHeroComingSoonData: HeroData = {
  heroType: 'heroDefault',
  headline: "Pre-order now! We'll be there soon",
  address: '1, LATCHFORD PLACE, HEMEL HEMPSTEAD, HP1 1AB',
  packageType: {
    listItems: [
      { key: 'RES', label: 'For Home' },
      { key: 'HWRK', label: 'For Home Worker' },
      { key: 'BUS', label: 'For Business' },
    ],
  },
  checklist: {
    listItems: {
      list: [
        { name: 'Be the first to know when we are available' },
        { name: 'Zero commitment option' },
      ],
    },
    smallPrint:
      'We will let you know as soon as we are ready to connect you to our full fibre network.',
  },
  aside: {
    heading: 'Not ready to pre-order-yet?',
    body: "That's okay, you can also ",
    CallToAction: {
      label: 'register your interest',
      link: '/service-unavailable',
    },
  },
};

export const mockHeroRegisterInterestData: HeroData = {
  heroType: 'heroDefault',
  headline: 'Register your interest. We are not in your area.',
  address: '1, LATCHFORD PLACE, HEMEL HEMPSTEAD, HP1 1AB',
  checklist: {
    listItems: {
      list: [
        { name: 'Be the first to know when we are available' },
        { name: 'Fixed price' },
        { name: 'Zero commitment option' },
      ],
    },
    smallPrint:
      'We will let you know as soon as we are ready to connect you to our full fibre network.',
  },
};
