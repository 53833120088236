import React, { FC } from 'react';

import { Hero as Molecule } from '@components/Molecules';

import { HeroT } from './types';

import { mockHeroHomepageLandingData } from '@components/Molecules/Hero/__mockData__';

export const Hero: FC<HeroT> = ({
  preHeading,
  heading,
  subHeading,
  description,
  image,
  theme,
  featureList,
  imageMobile,
  defaultAddressType,
  postcodeLabel,
  displayPostCodeChecker,
  cta,
  smallText,
  offer,
  AddHeadingGradient,
  AddCountdownTimer,
  TimerEnd,
  paddingTop,
  paddingBottom,
  imageScaleMode,
  imagePosition,
  subtitleColor,
  descriptionColor,
  timerBackgroundColor,
}) => {
  return (
    <>
      <Molecule
        data={{
          ...mockHeroHomepageLandingData,
          featureList: { list: featureList },
          preHeading,
          headline: heading,
          subHeadline: subHeading,
          featureImage: image,
          mobileImage: imageMobile,
          description,
          smallText,
          offer,
          heroType: theme === 'main' ? 'heroMain' : 'heroDefault',
          defaultAddressType: defaultAddressType,
          postcodeLabel: postcodeLabel,
          displayPostCodeChecker,
          cta,
          AddHeadingGradient,
          AddCountdownTimer,
          TimerEnd,
          paddingTop,
          paddingBottom,
          imageScaleMode,
          imagePosition,
          subtitleColor,
          descriptionColor,
          timerBackgroundColor,
        }}
      />
    </>
  );
};
