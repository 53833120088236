import { CardPackageT } from '@components/Organisms/ChoosePackage/types';
import usePostcodeContext from '@hooks/usePostcodeContext';
import { chooseMonthlyPriceByPostcode, fromMarkdown, getMonthlyPriceCalculated } from '@lib/utils';
import replacePlaceholderWithTags from '@lib/utils/replacePlaceholderWithTags';
import { useMemo } from 'react';
import styled from 'styled-components';
import Benny from '@public/box-top-benny-lg_alpha.png';
import Image from 'next/image';
import { breakpoints } from '@theme/breakpoints';

const STYLED = {
  benny: styled.div`
    position: absolute;
    top: -6.5rem;
    left: 5%;
    width: 90%;
    height: 8rem;

    @media ${breakpoints.desktop} {
      top: -6rem;
    }

    img {
      object-fit: contain;
    }
  `,
  card: styled.article`
    position: relative;
    padding: 2rem 1rem;
    -webkit-box-shadow: 0px 5px 9px 5px rgba(124, 146, 213, 0.3);
    -moz-box-shadow: 0px 5px 9px 5px rgba(124, 146, 213, 0.3);
    box-shadow: 0px 5px 9px 5px rgba(124, 146, 213, 0.3);
    background-color: var(--color-blue);
    margin-top: 10rem;
    @media ${breakpoints.mobilelg} {
      max-width: 25rem;
    }
    @media ${breakpoints.desktop} {
      margin-top: 4rem;
      max-width: 28rem;
      padding: 3rem 2rem;
    }
  `,
  benefitTag: styled.div`
    position: absolute;
    top: -3rem;
    right: 0;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background: var(--colors-gradient-tertiary-2);
    padding: 0.4rem 0.6rem;
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
    font-family: var(--fonts-family-secondary);
    background: transparent linear-gradient(300deg, #f4111b 0%, #0402fd 100%) 0 0 no-repeat
      padding-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    z-index: 2;

    line-height: 1.1;

    @media ${breakpoints.desktop} {
      right: -2rem;
    }

    span {
      font-size: 1.5em;
      margin-bottom: -0.25rem;
    }
  `,

  preHeading: styled.p`
    font-size: 1.1rem;
    font-weight: 500;
    font-family: var(--font-geomanist);
    color: black;
    margin-bottom: -0.25rem;
    @media ${breakpoints.desktop} {
      font-size: 1.25rem;
    }
  `,
  heading: styled.h3`
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    color: var(--color-primary);
    font-weight: 600;
    @media ${breakpoints.desktop} {
      font-size: 2.5rem;
    }
  `,
  measurements: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 0.5rem;
    margin: 1rem 0 1.5rem;
    * {
      font-family: var(--font-geomanist);
      color: black;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1;
      @media ${breakpoints.desktop} {
        font-size: 1.66rem;
      }
    }

    small {
      display: block;
      color: var(--colors-grey-50);
      font-weight: 500;
      font-size: 0.8rem;
      @media ${breakpoints.desktop} {
        font-size: 0.9rem;
      }
    }

    .measurement {
      flex: 1;
      &__value {
        font-size: 2em;
      }
    }
  `,
  features: styled.div`
    ul {
      font-family: var(--font-geomanist);
      list-style: disc;
      padding-left: 1.5rem;
      li {
        margin-bottom: 0.25rem;
        @media ${breakpoints.desktop} {
          font-size: 1.25rem;
        }
      }
    }
  `,
};
interface PropsI {
  product: CardPackageT | undefined;
  productFeatures: string;
  benefitTag?: string;
}

const EverydayFibreCard = ({ product, productFeatures, benefitTag = '' }: PropsI) => {
  const { postcodeItem, isCurrentPostcodePreorder } = usePostcodeContext();
  const { name, speed, monthly_price, preorder_montly_price, exclude_vat_price } = product || {};

  const monthlyPrice = useMemo(() => {
    return chooseMonthlyPriceByPostcode({
      postcodeItem,
      isCurrentPostcodePreorder,
      preorder_montly_price,
      monthly_price,
    });
  }, [postcodeItem, isCurrentPostcodePreorder, preorder_montly_price, monthly_price]);

  const monthlyPriceCalculated = useMemo(() => {
    return getMonthlyPriceCalculated({
      monthly_price: monthlyPrice,
      exclude_vat_price,
      hasHomePhone: false,
      homePhoneProduct: null,
      addressType: 'RES',
      promo: null,
    });
  }, [monthlyPrice, exclude_vat_price]);

  if (!product) return null;

  return (
    <STYLED.card>
      <STYLED.benny>
        <Image src={Benny} alt="Benny holding onto the product card" layout="fill" />
      </STYLED.benny>
      {benefitTag && (
        <STYLED.benefitTag
          dangerouslySetInnerHTML={{
            __html: replacePlaceholderWithTags(benefitTag, {
              tag: 'span',
            }),
          }}
        ></STYLED.benefitTag>
      )}
      <header>
        <STYLED.preHeading>Your package</STYLED.preHeading>
        <STYLED.heading>{name}</STYLED.heading>
        <div></div>
      </header>
      <STYLED.measurements>
        <div className="measurement">
          <p>
            <span className="measurement__value">{speed}</span>Mbps
          </p>
          <small>Average download/upload</small>
        </div>
        <p className="measurement">
          <span className="measurement__value">£{monthlyPriceCalculated}</span>/month
        </p>
      </STYLED.measurements>
      <STYLED.features>{fromMarkdown({ text: productFeatures, isHeading: false })}</STYLED.features>
    </STYLED.card>
  );
};

export default EverydayFibreCard;
