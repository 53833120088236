import React, { FC } from 'react';

import { TrustPilotBox as Organism } from '@components/Organisms';

import { TrustPilotBoxT } from '@factories/RowFactory/components/TrustPilotBox/types';

export const TrustPilotBox: FC<TrustPilotBoxT> = ({
  heading,
  description,
  reviewsHeading,
  display,
}) => {
  return (
    <Organism
      heading={heading}
      description={description}
      reviewsHeading={reviewsHeading}
      display={display}
    />
  );
};
