import replacePlaceholderWithTags from '@lib/utils/replacePlaceholderWithTags';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import { Copy } from '@components/Molecules';
import { CopyToClipboard } from '@components/Atoms/CopyToClipboard/CopyToClipboard';

const StyledHeading = styled.h2`
  font-family: var(--font-geomanist);
  font-weight: 500;
  font-size: 2.5rem;
  color: var(--colors-primary-bright);
  text-align: center;
  padding: 0 1rem;
  line-height: 1.05;

  max-width: 36rem;
  margin: 0 auto;

  @media ${breakpoints.tablet} {
    font-size: 3rem;
  }

  strong {
    color: black;
    font-weight: 500;
  }
`;

const StyledOffer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-geomanist);
  font-weight: 300;
  font-size: 1rem;
  color: black;
  margin-top: 1rem;

  small {
    font-weight: 300;
    font-family: var(--font-geomanist);
  }

  @media ${breakpoints.tablet} {
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;

    small {
      font-size: 1rem;
    }
  }
`;

const StyledPreChoosePackageHeader = styled.div`
  @media ${breakpoints.desktopLarge} {
    padding-top: 1rem;
    margin-bottom: 6rem;
  }
`;

const StyledCode = styled.div`
  background: linear-gradient(90deg, #0402fd, #f4111b 105%);
  color: white;
  font-weight: 500;
  font-size: 1.5rem;
  font-family: var(--font-geomanist);
  padding: 0.4rem 1rem 0.2rem;
  border-radius: 0.75rem;
`;

const PreChoosePackageHeader = ({ preChoosePackageText, preChoosePackagePromoCode }) => {
  return (
    <StyledPreChoosePackageHeader>
      <StyledHeading
        dangerouslySetInnerHTML={{
          __html: replacePlaceholderWithTags(preChoosePackageText, {
            tag: 'strong',
          }),
        }}
      />
      <StyledOffer>
        <small>Use code</small>
        <CopyToClipboard text={preChoosePackagePromoCode}>
          <StyledCode>{preChoosePackagePromoCode}</StyledCode>
        </CopyToClipboard>
        <small>at checkout</small>
      </StyledOffer>
    </StyledPreChoosePackageHeader>
  );
};

export default PreChoosePackageHeader;
