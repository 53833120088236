import React from 'react';
import { HeroCentred as Organism } from '@components/Molecules/HeroCentred/HeroCentred';

export const HeroCentred = ({ display, title, subtitle, body, imageLeft, imageRight, cta }) => {
  return (
    <Organism
      display={display}
      title={title}
      subtitle={subtitle}
      body={body}
      imageLeft={imageLeft}
      imageRight={imageRight}
      cta={cta}
    />
  );
};
