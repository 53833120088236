import React, { FC } from 'react';

import { NeedAHand as Organism } from '@components/Organisms';
import { NeedAHandI } from './types';

export const NeedAHand: FC<NeedAHandI> = ({
  heading,
  text,
  display,
}) => {
  return (
    <Organism
      heading={heading}
      text={text}
      display={display}
    />
  );
};
