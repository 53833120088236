import React, { FC } from 'react';

import { ImageCardT } from './types';
import { ImageCard as Organism } from '@components/Organisms';

export const ImageCard: FC<ImageCardT> = ({ ImageCard }) => {
  const {
    display = true,
    theme,
    subheading,
    heading,
    body,
    image,
    imagePosition,
    CTA = undefined,
    hexagonBackground = false,
  } = ImageCard;

  return (
    <Organism
      display={display}
      theme={theme}
      heading={heading}
      subheading={subheading}
      body={body}
      image={image}
      imagePosition={imagePosition}
      CTA={CTA}
      hexagonBackground={hexagonBackground}
    />
  );
};
