import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export default styled.section`
    background-color: var(--color-lightest-blue);
    padding: 4rem 2rem;
    position: relative;
    text-align: center;

    h2 {
        font-family: var(--font-geomanist);
        font-weight: 500;
        font-size: 2rem;
    }

    @media (${breakpoints.tablet}) {
        padding: 4rem 2rem;
    }

    .radio-row {
        padding: 1rem;
        align-items: center;
        max-width: 14rem !important;
    }

    .radio-row {
        span:last-of-type {
            text-align: left;
            max-width: 80%;
        }
    }
    .radio-row__label {
        padding-left: 2.5rem;
    }

    .radio-row__label::before {
        position: absolute;
        padding-left: 0;
        margin-left: -2.5rem;
        top: calc(50% - .75rem);
    }
`;